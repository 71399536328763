<template>
	<a-card>
		<a-form ref='form' :model='search' :style="{'marginBottom':'20px'}" :initialValues="search"
			:onFinish='pageSearchChange' layout='inline'>
			<a-form-item label="代理商" name='keywords'>
				<a-input v-model:value="search.keywords" style="width:250px" placeholder="请输入代理商名称、账号"></a-input>
			</a-form-item>
			<a-form-item label="充值时间" name='level_id'>
				<a-range-picker v-model:value="time" valueFormat='YYYY-MM-DD' format='YYYY-MM-DD' @change="onChange" />
			</a-form-item>
			<a-form-item>
				<a-button type="primary" htmlType='submit'>搜索</a-button>
			</a-form-item>
		</a-form>
		<div style="margin-top: 1.25rem;">
			<a-table bordered :data-source="dataSource" :columns="columns" :pagination='false' rowKey='id' :loading='listLoading'></a-table>
			<a-pagination :style="{marginTop:'20px',textAlign:'right'}" show-size-changer show-quick-jumper
				:current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
				@showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
		</div>
	</a-card>
</template>

<script>
	import { ref, onMounted, reactive, toRefs, inject } from 'vue';
	import { CheckOutlined } from '@ant-design/icons-vue';
	import { getRecordList } from "@/api/financial";
	import { message } from 'ant-design-vue';
	import { $iscode } from "@/utils/app";
	let defSearch = {
		page: 1,
		limit: 10,
		type: 'recharge',
		keywords: '',
    start_time:'',
    end_time:''
	};
	export default {
		components: {
		},
		setup(props) {
			let search = ref({ ...defSearch });
			let total = ref(1);
			const state = reactive({
				listLoading: false,
				tit: '',
				list: '全部',
				dataSource: [],
				columns: [
				{
					title: "公司名称",
					dataIndex: "agent_company",
					key: "agent_company",
				},
				{
					title: "代理商账号",
					dataIndex: "account",
					key: "account",
				},
				{
					title: '充值金额',
					dataIndex: 'number',
				},
				{
					title: '充值后余额',
					dataIndex: 'balance',
				},
				{
					title: '充值时间',
					dataIndex: 'create_time',
				},
				{
					title: '操作人',
					dataIndex: 'admin_username',
				},
				{
					title: '备注',
					dataIndex: 'mark',
				}

				],
			});
			const $route = inject('$route')
			const $Modal = inject('$Modal')
			const $router = inject('$router')
			// 页面筛选项搜索
			const pageSearchChange = () => {
				search.value = { ...search.value, page: 1 };
				initData(search.value);
			};
			// 分页当前页切换
			const pageCurrentChange = (page, pageSize) => {
				search.value.page = page;
				initData(search.value);
			};
			// 分页当前页显示多少条切换
			const pageSizeChange = (current, size) => {
				search.value.page = 1;
				search.value.limit = size;
				initData(search.value);
			};
			const initData = async (values) => {
				state.listLoading = true;
				try {
					let res = await getRecordList(values).then(res => res.data);
					state.listLoading = false;
					if ($iscode(res)) {
						state.dataSource = res.data.data;
						total.value = res.data.total;
					} else {
						message.error(res.message);
					}
				} catch (e) {
					state.listLoading = false;
				}
			};
			const time=ref()
			const onChange = (e) => {
				if (e.length > 0) {
					search.value.start_time = e[0]
					search.value.end_time = e[1]
				} else {
					search.value.start_time = ''
					search.value.end_time = ''
				}
			}
			onMounted(() => {
				initData(search.value);
			});
			return {
				...toRefs(state),
				initData,
				search,
				total,
				pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
				onChange,
				time
			}

		}
	}
</script>

<style lang="less">
	.editable-cell {
		position: relative;

		.editable-cell-input-wrapper,
		.editable-cell-text-wrapper {
			padding-right: 24px;
		}

		.editable-cell-text-wrapper {
			padding: 5px 24px 5px 5px;
		}

		.editable-cell-icon,
		.editable-cell-icon-check {
			position: absolute;
			right: 0;
			width: 20px;
			cursor: pointer;
		}

		.editable-cell-icon {
			line-height: 18px;
			display: none;
		}

		.editable-cell-icon-check {
			line-height: 28px;
		}

		.editable-cell-icon:hover,
		.editable-cell-icon-check:hover {
			color: #108ee9;
		}

		.editable-add-btn {
			margin-bottom: 8px;
		}
	}

	.editable-cell:hover .editable-cell-icon {
		display: inline-block;
	}
</style>